const Submission = (props) => {
    const { num, assessmentID, errorMsg } = props;
    return (
        <>
            <div className="my-10">
                {
                    num === 6 ? (
                        <>
                            <h1 className="text-center">
                                {
                                    errorMsg.join(' ')
                                }
                            </h1>
                            <p>
                                Your submission was not successful. If you believe this to be in error, please email <a href="mailto:bba@bcit.ca">bba@bcit.ca</a> with your name and student ID and we will get back to you within three business days.
                            </p> 
                        </>
                    ) :
                    num === 7 && (
                        <>
                            <h1 className="text-center">Thank you for submitting your BBA Assessment Form</h1>
                            <p>
                                The BBA Department will carefully review your application and may contact you if clarifications are necessary. You will receive a summary of your submission via the email provided in your assessment. <br/><br/>

                                <b>Your assessment ID#: </b>{assessmentID} <br/><br/>

                                If you have any questions about the BBA or your application please contact <a href="mailto:bba@bcit.ca">bba@bcit.ca</a> or you can find out more about the program at the BCIT Business of Business Administration <a href="https://www.bcit.ca/programs/bachelor-of-business-administration-bachelor-of-business-administration-full-time-part-time-9975bba/">website</a>.
                            </p>
                        </>
                    )
                }
            </div>
        </>
    )
}

export default Submission;